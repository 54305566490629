.repair {
    display: grid;
    grid-row-gap: 15px;
    grid-template-columns: 1fr;

    @include media-breakpoint-up(md) {
        grid-column-gap: 30px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 140px 140px 140px  140px 140px;
    }

    @include media-breakpoint-up(xl) {
        grid-row-gap: 24px;
        grid-column-gap: 24px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 295px 295px;
    }
}

.repair-offer {
    color: $gray2;
    font-size: rem(14);
    line-height: rem(18);
    background: #F7FBFE;
    padding: rem(18) rem(20) rem(36) rem(30);

    &__icon {
        width: 55px;
        margin-bottom: rem(12);
    }

    &__title {
        font-weight: 500;
        font-size: rem(18);
        line-height: rem(19);
        margin-bottom: rem(10);
        text-transform: uppercase;
    }

    &__text {
        margin-bottom: rem(15);
    }

    @include media-breakpoint-up(md) {
        grid-row: span 2;
    }

    @include media-breakpoint-up(xl) {
        grid-row: span 1;

        &__text {
            margin-bottom: rem(24);
        }
    }
}

.repair-item {
    color: $gray2;
    display: block;
    height: rem(140);
    position: relative;
    text-decoration: none;

    &:before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        content: '';
        display: block;
        position: absolute;
        border: 2px solid $yellow;
        opacity: 0;
        visibility: hidden;
        transition: .25s;
    }

    &:hover {
        color: $gray2;
        text-decoration: none;

        &:before {
            opacity: 1;
            visibility: visible;
            transition: .25s;
        }

        .btn-white {
            fill: $black;
            color: $black;
            background: linear-gradient(90deg, #F5B835 0%, #FBD864 100%);
            box-shadow: 0px 5px 10px #FFD54F;
        }
    }

    &__image {
        z-index: 1;
        height: 100%;
        position: relative;
    }

    &__content {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        padding: rem(12);
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn {
        width: rem(150);
    }

    @include media-breakpoint-up(md) {
        height: inherit;

        &--06 {
            grid-row: span 2;
        }
    }


    @include media-breakpoint-up(xl) {

        &__content {
           align-items: flex-end;
        }

        &--06 {
            grid-row: span 1;
        }
    }
}


@include media-breakpoint-up(md) {}
@include media-breakpoint-up(lg) {}
@include media-breakpoint-up(xl) {}
@include media-breakpoint-up(xxl) {}
