input:not([type=checkbox]):not([type=radio]),
textarea,
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input, textarea {
    outline: none;
    &:focus:required:invalid { border-color: red }
    &:required:valid { border-color: green }
}

:active, :hover, :focus {
    outline: 0 !important;
    outline-offset: 0 !important;
}

option::selection {background: #ccc;}
option::-moz-selection {background: #ccc;}
option::-webkit-selection {background: #ccc; color:#fff;}

.field {
    width: 100%;
    display: block;
    font-weight: 400;
    position: relative;
    background: transparent;
    $input-selector: "input[type=text], input[type=email], input[type=password], textarea";

    #{$input-selector} {
        margin: 0;
        width: 100%;
        border: none;
        height: rem(52);
        outline: none;
        color: $gray4;
        box-shadow: none;
        font-weight: 400;
        font-size: rem(14);
        line-height: rem(22);
        padding: rem(14) rem(26);
        background: $white;
        display: inline-block;
        vertical-align: middle;
        border: rem(1) solid $gray4;
        @include placeholder($gray4);

        &::-ms-clear {
            display: none;
            width: 0;
            height: 0;
        }

        &:focus,
        &:active {
            outline: none;
            @include placeholder(transparent);
        }


        &.error {
            border-color: red !important;
        }
    }

    textarea {
        height: auto;
        resize: none;
    }

    &--white {

        #{$input-selector} {
            color: $white;
            padding: rem(13) rem(26);
            border: rem(2) solid $white;
            @include placeholder($white);
            background: transparent;

            &:active {
                outline: none;
                @include placeholder(transparent);
            }
        }
    }
}
