.btn {
    border: none;
    outline: none;
    height: rem(52);
    cursor: pointer;
    font-weight: 500;
    font-size: rem(14);
    line-height: rem(18);
    padding: rem(8) rem(52);
    user-select: none;
    text-align: center;
    white-space: nowrap;
    position: relative;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    background: transparent;
    fill: #000;

    &:hover {
        text-decoration: none;
    }

    &:active {
    }

    &:focus,
    &.focus {
        outline: 0;
    }

    span {
        display: inline-block;
    }

    i {
        font-size: 0;
        line-height: 0;
        display: inline-block;
        height: rem(18);
    }

    span + i {
        margin-left: rem(8);
    }

    i + span {
        margin-left: rem(8);
    }
}

.btn-yellow {
    fill: $gray;
    color: $gray;
    background: linear-gradient(90deg, #F5B835 0%, #FBD864 100%);

    &:hover {
        fill: $gray;
        color: $gray;
        background: linear-gradient(90deg, #FBD864 0%, #F5B835 100%);
    }

    &:active {
        background: linear-gradient(90deg, #F5B835 0%, #FBD864 100%);
    }
}

.btn-white {
    fill: $black;
    color: $black;
    background: $white;

    &:hover {
        fill: $black;
        color: $black;
        background: linear-gradient(90deg, #F5B835 0%, #FBD864 100%);
        box-shadow: 0px 5px 10px #FFD54F;
    }

    &:active {
        fill: $black;
        color: $black;
        background: $white;
    }
}

.btn-border {
    color: $yellow;
    background: $white;
    border: 1px solid $yellow;

    &:hover {
        color: $black;
        background: $yellow;
    }

    &:active {
        color: $yellow;
        background: $white;
    }
}

.btn-shadow {
    box-shadow: 0px 5px 10px #FFD54F;
}

.btn-yellow-text {
    color: $yellow;
    box-shadow: 0px 5px 10px #FFD54F;

    &:hover {
        color: $yellow-light;
        background: $white;
    }
}

.btn-sm {
    height: rem(38);
    padding: rem(4) rem(24);
}

.btn-md {
    height: rem(40);
    padding: rem(4) rem(32);
    text-transform: uppercase;
}

.btn-link {
    margin: 0;
    padding: 0;
    color: $black;
    border: none;
    outline: none;
    cursor: pointer;
    display: inline-flex;
    background: transparent;
    text-decoration: underline;

    &:hover {
        color: $black;
        text-decoration: none;
    }
}

.btn-calc {
    margin: 0;
    border: none;
    outline: none;
    display: inline-block;
    cursor: pointer;
    color: #F5B835;
    min-height: rem(34);
    padding: 0 0 0 rem(40);
    background: transparent;
    text-decoration: underline;
    background: url("../img/header_calculation_image.svg") 0 50% no-repeat;

    span {
        display: block;
    }

    &:hover {
        color: #F5B835;
        text-decoration: none;
    }
}

.btn-nav {
    margin: 0;
    padding: 0;
    z-index: 20;
    width: 60px;
    height: 60px;
    display: block;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    background: $white url("../img/slider_arrow.svg") 50% 50% no-repeat;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &--next {
        transform: rotate(180deg);
    }
}
