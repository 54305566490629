.calc {
    padding-top: rem(56);
    padding-bottom: rem(100);
    background: url("../img/calc__bg.webp") 50% 50% no-repeat;
    background-size: cover;

    &__box {
        position: relative;
        background: $white;
        padding-top: rem(24);

        &:after,
        &:before {
            top: 0;
            bottom: 0;
            z-index: 1;
            content: '';
            width: 500px;
            position: absolute;
            background: $white;
        }

        &:after {
            right: 100%;
        }

        &:before {
            left: 100%;
        }
    }

    &__step {
        z-index: 50;
        position: relative;
        display: none;

        &.active {
            display: block;
        }
    }

    &__header {
        font-weight: 700;
        font-size: rem(24);
        line-height: rem(28);
        margin-bottom: rem(16);
        font-family: $font-family-secondary;
    }

    &__body {
        position: relative;
        padding-left: rem(100);
        padding-bottom: rem(80);
    }

    &__media {
        top: 0;
        width: 320px;
        right: calc(100% - 73px);
        bottom: rem(80);
        position: absolute;
    }

    &__summary {
        left: 0;
        right: 0;
        z-index: 20;
        bottom: rem(-75);
        position: absolute;
    }

    &__progress {
        margin-bottom: rem(38);
    }

    &__main {
        height: 345px;
        display: flex;
        flex-direction: column;
    }

    &__content {
        flex-grow: 1;
    }

    &__title {
        color: $gray2;
        font-weight: 500;
        font-size: rem(18);
        line-height: rem(19);
        margin-bottom: rem(30);
        text-transform: uppercase;

        &--sm {
            margin-bottom: rem(20);
        }
    }

    &__text {
        color: $gray1;
        font-weight: 300;
        font-size: rem(18);
        line-height: rem(19);
        margin-bottom: rem(20);
    }

    &__item {

        &:not(:last-child) {
            margin-bottom: rem(40);
        }

        &--sm {

            &:not(:last-child) {
                margin-bottom: rem(4);
            }
        }
    }

    &__nav {
        display: flex;
        align-items: center;
    }

    &__button {

        &:not(:last-child) {
            margin-right: rem(10);
        }
    }

    &__form {
        max-width: rem(320);
    }

    @include media-breakpoint-up(md) {
        padding-bottom: rem(56);

        &__box {
            padding: rem(36) 0;
        }

        &__body {
            display: flex;
            padding-bottom: 0;
            padding-left: rem(160);

            &--last {
                padding-left: 0;
            }
        }

        &__summary {
            top: auto;
            right: auto;
            left: auto;
            bottom: auto;
            width: rem(225);
            position: relative;
            flex-shrink: 0;
        }

        &__main {
            flex-grow: 1;
        }

        &__media {
            top: 0;
            width: 320px;
            right: calc(100% - 138px);
            bottom: 0;
        }
    }

    @include media-breakpoint-up(lg) {

        &__progress {
            margin-bottom: rem(48);
        }

        &__body {
            padding-left: 0;
        }

        &__media {
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
            position: relative;
        }

        &__main {
            padding: 0 rem(22);
        }
    }

    @include media-breakpoint-up(xl) {
        padding: rem(48) 0;

        &__header {
            font-size: rem(30);
            line-height: rem(32);
            margin-bottom: rem(18);
        }

        &__box {
            padding: rem(36) rem(56);

            &:after,
            &:before {
                display: none;
            }
        }

        &__summary {
            width: rem(320);

            &--last {
                width: rem(680);
            }
        }
    }
}

.calc-progress {
    position: relative;

    &:before {
        height: 8px;
        left: 8px;
        right: 8px;
        bottom: 3px;
        z-index: 1;
        content: '';
        position: absolute;
        background: #F2F2F2;
    }

    &__line {
        left: 8px;
        bottom: 5px;
        height: 4px;
        z-index: 20;
        position: absolute;
        background: $yellow;
    }

    &__wrap {
        margin: 0 rem(-6);
        z-index: 10;
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    &__num {
        width: rem(24);
        color: #828282;
        text-align: center;
        font-weight: 700;
        font-size: rem(30);
        line-height: rem(30);
        margin-bottom: rem(2);
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 1px;
    }

    &__point {
        width: 14px;
        height: 14px;
        margin: 0 auto;
        border-radius: 50%;
        background: #E0E0E0;
        border: 2px solid #E0E0E0;
    }

    &__item {

        &.active {

            .calc-progress {

                &__num {
                    color: #333;
                    -webkit-text-fill-color: #333;
                }

                &__point {
                    background: $yellow;
                }
            }
        }
    }

}

.calc-summary {
    color: $black;
    font-size: rem(14);
    line-height: rem(24);
    position: relative;
    padding: rem(14) rem(10) rem(14) rem(134);
    background: linear-gradient(90deg, #F5B835 0%, #FBD864 100%);

    &__image {
        bottom: rem(12);
        left: rem(25);
        width: 93px;
        position: absolute;

        &--last {
            bottom: 0;
            left: rem(-8);
            width: 134px;
        }
    }

    &__scale {
        height: rem(4);
        margin-top: rem(8);
        margin-bottom: rem(5);
        position: relative;
    }

    &__value {
        height: 100%;
        background: $white;
        position: relative;
    }

    @include media-breakpoint-up(md) {
        height: 100%;
        padding: rem(12);

        &__image {
            left: auto;
            right: auto;
            bottom: auto;
            width: 172px;
            position: relative;
            margin: 0 auto rem(8) auto;

            &--last {
                left: -20px;
                width: 244px;
                margin-top: rem(20);
            }
        }
    }

    @include media-breakpoint-up(xl) {

        &--last {
            padding-left: rem(26);
            padding-top: rem(140);
            font-size: rem(18);
            line-height: rem(24);

            .calc-summary__scale {
                width: rem(136);
            }
        }

        &__image {

            &--last {
                bottom: 0;
                left: auto;
                right: rem(-36);
                margin: 0;
                width: rem(408);
                position: absolute;
            }
        }

        &__content {
            width: rem(270);
        }
    }
}


@include media-breakpoint-up(md) {}
@include media-breakpoint-up(lg) {}
@include media-breakpoint-up(xl) {}
@include media-breakpoint-up(xxl) {}
