.header {
    top: 0;
    left: 0;
    right: 0;
    z-index: 900;
    position: fixed;
    background: $white;
    border-bottom: 1px solid #D5DEE4;

    &__body {
        height: rem(72);
        position: relative;
        padding-left: rem(52);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__content {
        display: flex;
        align-items: center;
    }

    &__contact {
        height: rem(37);
        display: flex;
        align-items: center;
        position: relative;
    }

    &__phone {
        color: #333;
        font-weight: 400;
        font-size: rem(24);
        line-height: rem(24);
        white-space: nowrap;
        display: inline-block;
        text-decoration: none;

        &:hover {
            color: #333;
            text-decoration: none;
        }
    }

    &__worktime {
        right: 0;
        top: calc(100% - 6px);
        color: $gray-light;
        font-weight: 400;
        font-size: rem(14);
        line-height: rem(14);
        white-space: nowrap;
        position: absolute;
        display: inline-block;

        &:before {
            left: rem(-8);
            top: calc(50% - 1px);
            width: 4px;
            height: 4px;
            content: '';
            position: absolute;
            border-radius: 50%;
            background: linear-gradient(180deg, #AECF53 0%, #89BB45 100%);
        }
    }

    &__messenger {
        width: 37px;
        height: 37px;
        display: none;
        overflow: hidden;
        border-radius: 50%;
        margin-right: rem(5);
    }

    &__calc {
        display: none;
    }

    &__hamburger {
        left: 0;
        position: absolute;
        top: calc(50% - 19px);
    }

    &__email {
        color: $gray;
        display: none;
        font-weight: 400;
        font-size: rem(16);
        line-height: rem(18);
        white-space: nowrap;
        text-decoration: none;
        margin-right: rem(88);

        &:hover {
            color: $gray;
            text-decoration: none;
        }

        i {
            flex-shrink: 0;
            height: rem(37);
            display: inline-block;
            margin-right: rem(12);
        }
    }

    &__callback {
        display: none;
    }

    @include media-breakpoint-up(md) {

        &__body {
            padding-left: rem(60);
        }

        &__messenger {
            display: block;
        }

        &__phone {
            margin-left: rem(2);
        }

        &__calc {
            display: block;
        }
    }

    @include media-breakpoint-up(lg) {
        top: auto;
        left: auto;
        right: auto;
        position: relative;

        &__body {
            padding-left: 0;
        }

        &__hamburger {
            display: none;
        }
    }

    @include media-breakpoint-up(xl) {

        &__email {
            display: flex;
            align-items: center;
        }

        &__callback {
            display: block;
            margin-left: rem(30);
        }
    }

    @include media-breakpoint-up(xxl) {}
}

.header-logo {
    font-weight: 300;
    font-size: rem(12);
    line-height: 105%;
    color: $gray-light;
    text-decoration: none;
    display: flex;
    align-items: center;

    &:hover {
        color: $gray-light;
        text-decoration: none;
    }

    &__image {
        width: rem(55);
        display: block;
        flex-shrink: 0;
    }

    &__text {
        display: none;
        margin-left: rem(10);
        padding-left: rem(10);
        border-left: 1px solid $gray-light;
    }

    @include media-breakpoint-up(md) {

        &__text {
            display: block;
        }
    }
}

.hamburger {
    width: 38px;
    height: 38px;
    background: linear-gradient(90deg, #F5B835 0%, #FBD864 100%);
    display: block;
    text-align: right;
    margin: 0;
    padding: 0;
    font-size: 0;
    line-height: 0;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50%;

    span,
    &:after,
    &:before {
        height: 2px;
        content: '';
        left: calc(50% - 12px);
        display: inline-block;
        position: absolute;
        transition-property: transform;
        transition-duration: .15s;
        transition-timing-function: ease;
        border-radius: 2px;
        background-color: $white;
    }

    &:after {
        width: 24px;
        top: calc(50% - 1px);
    }

    &:before {
        width: 24px;
        top: calc(50% - 7px);
    }

    span {
        width: 16px;
        top: calc(50% + 5px);
    }
}

.nav-open {

    .header__nav {
        transition: .3s;
        transform: translateX(0);
    }

    .header__layout {
        opacity: 1;
        visibility: visible;
    }

    .hamburger {

        span {
            opacity: 0;
            width: 100%;
        }

        &:after {
            transform: translateY(8px) translateX(0) rotate(45deg);
            width: 100%;
        }

        &:before {
            transform: translateY(-8px) translateX(0) rotate(-45deg);
            width: 100%;
        }
    }
}
