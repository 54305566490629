.modal {
    width: 100%;
    color: $white;
    position: relative;
    max-width: rem(376);
    border: 5px solid $yellow;
    padding: rem(42) rem(24) rem(48) rem(24);

    &--callback {
        background: url("../img/modal__callback.jpg") 50% 50% no-repeat;
        background-size: cover;
    }

    &--order {
        overflow: hidden;
        background: url("../img/modal__order.jpg") 50% 50% no-repeat;
        background-size: cover;
    }

    &__header {
        color: $yellow;
        font-weight: 700;
        font-size: rem(36);
        line-height: 126.5%;
        margin-bottom: rem(28);
        font-family: $font-family-secondary;
    }

    &__item {
        margin-bottom: rem(18);
    }

    &__privacy {
        font-size: rem(14);
        line-height: 136%;

        a {
            color: $white;
            text-decoration: underline;

            &:hover {
                color: $white;
                text-decoration: none;
            }
        }
    }

    .carousel__button {
        right: 0 !important;
        top: 0 !important;
    }

    @include media-breakpoint-up(md) {
        max-width: inherit;
        width: rem(580);
        padding: rem(42) rem(40);

        &--order {

            &:before {
                z-index: 1;
                top: rem(8);
                right: rem(-218);
                content: '';
                width: 544px;
                height: 816px;
                position: absolute;
                background: url("../img/modal__man.png") 50% 50% no-repeat;
            }
        }

        &__header {
            font-size: rem(40);
        }

        &__main {
            z-index: 10;
            width: rem(370);
            position: relative;
        }

        &__form {
            width: rem(320);
        }
    }
}
