.masters {
    margin-bottom: rem(32);

    &__main {
        overflow: visible;
    }

    &__item {

    }

    &__image {

    }

    &__name {
        color: $gray2;
        font-weight: 500;
        font-size: rem(14);
        line-height: rem(15);
        margin-top: rem(15);
    }

    &__skill {
        color: $gray4;
        font-size: rem(12);
        line-height: rem(13);
        text-transform: uppercase;
    }

    &__experience {
        color: $gray2;
        font-size: rem(14);
        line-height: rem(21);
        margin-top: rem(6);
    }

    .swiper-slide {
        width: rem(196);
    }

    @include media-breakpoint-up(md) {
        margin-bottom: rem(46);
    }

    @include media-breakpoint-up(xxl) {
        margin-bottom: rem(50);

        .swiper-slide {
            width: auto;
        }
    }
}
