@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu Regular'), local('Ubuntu-Regular'),
    url('../fonts/Ubuntu/Ubuntu-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu Light'), local('Ubuntu-Light'),
    url('../fonts/Ubuntu/Ubuntu-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu Light Italic'), local('Ubuntu-LightItalic'),
    url('../fonts/Ubuntu/Ubuntu-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu Medium'), local('Ubuntu-Medium'),
    url('../fonts/Ubuntu/Ubuntu-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu Italic'), local('Ubuntu-Italic'),
    url('../fonts/Ubuntu/Ubuntu-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu Bold'), local('Ubuntu-Bold'),
    url('../fonts/Ubuntu/Ubuntu-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu Bold Italic'), local('Ubuntu-BoldItalic'),
    url('../fonts/Ubuntu/Ubuntu-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu Medium Italic'), local('Ubuntu-MediumItalic'),
    url('../fonts/Ubuntu/Ubuntu-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}



@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans Italic'), local('FiraSans-Italic'),
    url('../fonts/FiraSans/FiraSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans Bold'), local('FiraSans-Bold'),
    url('../fonts/FiraSans/FiraSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans Medium Italic'), local('FiraSansMedium-Italic'),
    url('../fonts/FiraSans/FiraSansMedium-Italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans Light'), local('FiraSansLight'),
    url('../fonts/FiraSans/FiraSansLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans'), local('FiraSans'),
    url('../fonts/FiraSans/FiraSans.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans Medium'), local('FiraSansMedium'),
    url('../fonts/FiraSans/FiraSansMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans Light Italic'), local('FiraSansLight-Italic'),
    url('../fonts/FiraSans/FiraSansLight-Italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans Bold Italic'), local('FiraSans-BoldItalic'),
    url('../fonts/FiraSans/FiraSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}
