.price {
    position: relative;

    &__main {
        position: relative;
        margin-bottom: rem(15);
    }

    &__slider {
        overflow: visible;
    }

    &__nav {
        top: rem(40);
        z-index: 20;
        position: absolute;

        &--prev {
            left: rem(-10);
        }

        &--next {
            right: rem(-10);
        }
    }

    .swiper-slide {
        height: auto;
        padding-top: rem(90);
        padding-bottom: rem(15);

        &:last-child {
            display: none;
        }
    }

    @include media-breakpoint-up(md) {

        &__main {
            margin-bottom: rem(30);
        }

        &__nav {
            display: none;
        }

        .swiper-slide {
            width: rem(306);
        }
    }

    @include media-breakpoint-up(xxl) {

        .swiper-wrapper {
            display: grid;
            grid-row-gap: rem(30);
            grid-column-gap: rem(24);
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        .swiper-slide {
            width: auto;

            &:last-child {
                display: block;
                padding-top: 0;
                grid-column: span 2;
            }
        }

        &__main {
            margin-bottom: 0;
        }

        &__offer {
            display: none;
        }
    }
}

.price-item {
    position: relative;
    background: #F7FBFE;
    padding-top: rem(90);
    padding-bottom: rem(42);

    &__image {
        top: rem(-90);
        left: calc(50% - 70px);
        width: 140px;
        height: 140px;
        overflow: hidden;
        border-radius: 50%;
        position: absolute;
    }

    &__main {
        color: $gray2;
        width: rem(254);
        margin: 0 auto;
    }

    &__title {
        font-weight: 500;
        font-size: rem(18);
        line-height: rem(19);
        margin-bottom: rem(12);
        text-transform: uppercase;
    }

    &__content {
        font-size: rem(14);
        line-height: rem(27);

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        li {
            position: relative;
            padding-left: rem(20);

            &:before {
                width: 3px;
                height: 3px;
                content: '';
                left: rem(8);
                top: rem(12);
                border-radius: 50%;
                position: absolute;
                background: $gray2;
            }
        }
    }

    &__value {
        color: $gray2;
        bottom: rem(-15);
        left: calc(50% - 123px);
        width: rem(246);
        height: rem(36);
        padding: rem(4) rem(10);
        font-size: rem(12);
        line-height: rem(25);
        position: absolute;
        text-align: center;
        background: linear-gradient(90deg, #F5B835 0%, #FBD864 100%);

        strong  {
            display: inline;
            font-weight: 500;
            font-size: rem(24);
            line-height: rem(28);
        }
    }

    @include media-breakpoint-up(xxl) {

    }
}

.price-offer {
    color: $gray1;
    margin: 0 rem(-16);
    position: relative;
    font-size: rem(20);
    line-height: rem(25);
    padding: rem(16) rem(30) rem(24) rem(30);
    background: linear-gradient(90deg, #F5B835 0%, #FBD864 100%);

    &__image {
        margin: 0 auto;
        width: 278px;
    }

    &__header {
        white-space: nowrap;
        font-weight: 700;
        font-size: rem(40);
        line-height: rem(52);
        margin-bottom: rem(4);
        font-family: $font-family-secondary;
    }

    &__text {
        margin-bottom: rem(15);
    }

    @include media-breakpoint-up(md) {
        margin: 0;
        height: rem(397);
        padding: rem(60) rem(40) rem(30) rem(30);

        &__content {
            z-index: 10;
            width: rem(320);
            position: relative;
        }

        &__text {
            width: rem(300);
            margin-bottom: rem(56);
        }

        &__image {
            right: 0;
            bottom: 0;
            margin: 0;
            z-index: 1;
            width: 386px;
            position: absolute;
        }
    }


    @include media-breakpoint-up(xxl) {
        overflow: hidden;
        height: 100%;

        &__image {
            right: rem(-38);
        }
    }
}
