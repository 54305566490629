.nav {
    left: 0;
    top: rem(73);
    width: 100%;
    bottom: 0;
    z-index: 900;
    position: fixed;
    background: $white;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;
    transition: .3s;

    &__container {
        padding: 0 rem(16);
    }

    &__menu {
        margin: 0;
        padding: rem(10) 0;
        list-style: none;

        > li {
            position: relative;

            > a {
                padding: rem(8) 0;
                stroke: $black;
                color: $black;
                font-weight: 500;
                font-size: rem(14);
                line-height: rem(16);
                white-space: nowrap;
                text-decoration: none;
                text-transform: uppercase;
                display: flex;
                align-items: center;

                &:hover {
                    fill: $black;
                    color: $black;
                    text-decoration: none;
                }

                span {
                    display: inline-block;
                }

                i {
                    top: -1px;
                    height: rem(6);
                    font-size: 0;
                    line-height: 0;
                    margin-left: rem(4);
                    position: relative;
                    display: inline-block;
                }
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {

                    a {
                        color: $black;
                        display: block;
                        padding: rem(6) rem(16);
                        text-decoration: none;

                        &:hover {
                            color: $black;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        opacity: 1;
        visibility: visible;
        position: relative;
        overflow: visible;

        &__menu {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            > li {

                > a {
                    height: rem(52);
                }

                ul {
                    top: 100%;
                    left: rem(-16);
                    min-width: 100%;
                    white-space: nowrap;
                    position: absolute;
                    background: $white;
                    box-shadow: 0px 0px 25px #DDE9ED;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity .3s;

                    li {

                        &:first-child {

                            a {
                                padding-top: rem(16);
                            }
                        }

                        &:last-child {

                            a {
                                padding-bottom: rem(16);
                            }
                        }
                    }
                }

                &:hover {

                    ul {
                        opacity: 1;
                        visibility: visible;
                        transition: opacity .3s;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {

        &__menu {
            padding: 0 rem(40);

            > li {

                > a {
                    transition: .3s;

                    &:hover {
                        stroke: #F5B835;
                        color: #F5B835;
                        transition: .3s;
                    }
                }

                &:hover {

                    > a {
                        stroke: #F5B835;
                        color: #F5B835;
                        transition: .3s;
                    }
                }

                ul {

                    li {

                        a {

                            &:hover {
                                stroke: #F5B835;
                                color: #F5B835;
                            }
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-up(xxl) {}
}


.nav-open {
    max-height: 100%;
    overflow: hidden;

    .hamburger {

        span {
            opacity: 0;
            width: 100%;
        }

        &:after {
            width: 24px;
            transform: translateY(0px) translateX(0px) rotate(45deg);
        }

        &:before {
            width: 24px;
            transform: translateY(6px) translateX(0px) rotate(-45deg);
        }
    }

    .nav {
        opacity: 1;
        visibility: visible;
        transition: .3s;
    }

    @include media-breakpoint-up(lg) {
        max-height: inherit;
        overflow: visible;
    }
}
