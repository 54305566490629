.pretty-radio {
    font-size: 0;
    line-height: 0;
    font-weight: 400;
    position: relative;
    display: inline-block;

    &__input {
        @include visually-hidden();
    }

    &__label {
        color: $gray2;
        font-weight: 500;
        font-size: rem(14);
        line-height: rem(20);
        position: relative;
        padding-left: rem(30);
        display: block;

        &::before {
            top: 0;
            left: 0;
            z-index: 1;
            content: '';
            width: rem(20);
            height: rem(20);
            display: block;
            position: absolute;
            border-radius: 50%;
            border: 1px solid $gray5;
        }

        &:after {
            top: rem(3);
            left: rem(3);
            z-index: 2;
            content: '';
            width: rem(14);
            height: rem(14);
            display: block;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            border-radius: 50%;
            background: linear-gradient(90deg, #F5B835 0%, #FBD864 100%)
        }

        i {
            font-size: 0;
            line-height: 0;
            height: 37px;
            display: inline-block;
            flex-shrink: 0;
            margin-right: rem(8);
        }

        &--center {

            &::before {
                top: calc(50% - 10px);
            }

            &:after {
                top: calc(50% - 7px);
            }
        }
    }

    &__input:checked + .pretty-radio__label {

        &::before {
            border-color: $yellow;
        }

        &::after {
            opacity: 1;
            visibility: visible;
           // transform: scale(1);
            transition : all 0.3s cubic-bezier(0.35, 0.9, 0.4, 0.9);
        }
    }

    &__input:disabled + .pretty-radio__label {
        opacity: 0.4;
    }
}
