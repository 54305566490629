.why {
    overflow: visible;

    .swiper-slide {
        height: auto;
        width: rem(210);
        padding-top: rem(14);
    }

    &__item {
        height: 100%;
        position: relative;
        background: #F7FBFE;
        font-size: rem(18);
        line-height: rem(19);
        padding: rem(30) rem(16) rem(16) rem(22);
    }

    &__num {
        right: 0;
        top: rem(-14);
        color: #DBE9F3;
        position: absolute;
        font-weight: 900;
        font-size: rem(40);
        line-height: rem(40);
        font-family: $font-family-secondary;
    }

    &__text {
        font-weight: 300;
    }

    &__media {
        height: rem(70);
        margin-bottom: rem(8);
        display: flex;
        align-items: flex-end;
    }

    &__button {
        padding-top: rem(8);
    }

    @include media-breakpoint-up(md) {

        .swiper-slide {
            width: auto;
        }

        .swiper-wrapper {
            display: grid;
            grid-row-gap: rem(16);
            grid-column-gap: rem(30);
            grid-template-columns: 1fr 1fr 1fr  1fr 1fr 1fr;
        }

        .swiper-slide {
            grid-column: span 2;

            &:nth-child(7),
            &:nth-child(8) {
                grid-column: span 3;
            }
        }
    }

    @include media-breakpoint-up(lg) {

        .swiper-wrapper {
            grid-row-gap: rem(36);
            grid-column-gap: rem(24);
            grid-template-columns: 1fr 1fr 1fr  1fr;
        }

        .swiper-slide {
            grid-column: span 1;

            &:nth-child(7),
            &:nth-child(8) {
                grid-column: span 1;
            }
        }

        &__text {
            max-width: rem(230);
        }
    }

    @include media-breakpoint-up(xl) {

        &__item {
            padding-top: rem(28);
            padding-left: rem(26);
        }

        &__media {
            margin-bottom: rem(14);
        }
    }


    @include media-breakpoint-up(xxl) {}
}
