[data-spoiler-content] {
	overflow: hidden;
	max-height: 0;
	opacity: 0;
	will-change: max-height;
	transition: .3s;
}

[data-spoiler] {

	&.open {

		[data-spoiler-content] {
			opacity: 1;
			transition: .3s;
		}
	}
}

.spoilers {

	&__item {
        background: $white;
        box-shadow: 0px 0px 25px #DDE9ED;

        &:not(:last-child) {
            margin-bottom: rem(20);
        }

		&.open {

			.spoilers {

                &__header {
                    color: $yellow;
                    background: #333;
                }

                &__icon {

                    &:after {
                        transform: rotate(360deg);
                        transition: .3s;
                    }

                    &:before {
                        transform: rotate(180deg);
                        transition: .3s;
                    }
                }
            }
		}
	}

	&__header {
		width: 100%;
		color: $gray2;
		border: none;
		outline: none;
		text-align: left;
		background: $white;
		font-weight: 500;
		font-size: rem(18);
		line-height: rem(24);
        cursor: pointer;
        position: relative;
        padding: rem(16) rem(15);
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: space-between;
	}

	&__title {
		display: block;
		padding-right: rem(8);
	}

	&__icon {
		font-size: 0;
		line-height: 0;
		width: 40px;
        height: 40px;
        border-radius: 50%;
		display: inline-block;
        position: relative;
        background: linear-gradient(90deg, #F5B835 0%, #FBD864 100%);
		flex-shrink: 0;

        &:after,
        &:before {
            top: calc(50% - 1px);
            left: calc(50% - 8px);
            content: '';
            width: 16px;
            height: 2px;
            background: #333;
            position: absolute;
            transition: .3s;
        }

        &:after {
            transform: rotate(90deg);
        }
	}

	&__content {
        color: $gray2;
        font-size: rem(14);
        line-height: rem(18);

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
	}

	&__wrap {
		padding: rem(24) rem(15);
	}

    @include media-breakpoint-up(md) {

        &__header {
            padding-left: rem(30);
        }

        &__wrap {
            padding-left: rem(30);
        }
    }
}
