@import 'config';
@import './layout/functions';
@import './mixins/mixins';

// Plugins
@import 'node_modules/swiper/swiper';
@import 'node_modules/@fancyapps/ui/src/Fancybox/Fancybox';
@import './plugins/simplebar';
@import './plugins/hystmodal';
@import './plugins/air-datepicker';

// Layout
@import './layout/fonts';
@import './layout/base';
@import './layout/utilities';

@import './components/fullscreen';
@import './components/containers';
@import './components/grid';
@import './components/buttons';
@import './components/forms/forms';
@import './components/breadcrumbs';
@import './components/tabs';
@import './components/collapse';
@import './components/spoilers';
@import './components/dropdown';
@import './components/rating';


@import 'parts/header';
@import 'parts/footer';
@import 'parts/nav';
@import 'parts/modal';

@import 'parts/service';
@import 'parts/works';
@import 'parts/calc';
@import 'parts/why';
@import 'parts/showcase';
@import 'parts/estimation';
@import 'parts/offer';
@import 'parts/question';
@import 'parts/masters';
@import 'parts/portfolio';
@import 'parts/deadlines';
@import 'parts/repair';
@import 'parts/price';

@import 'content';


