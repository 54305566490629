.showcase {
    overflow: visible;

    .swiper-slide {
        width: rem(320);
    }

    &__item {
        background: $white;
        box-shadow: 0px 0px 25px #DDE9ED;
    }

    &__content {
        z-index: 10;
        color: $gray2;
        position: relative;
        padding: rem(56) rem(8) rem(42) rem(8);
    }

    &__name {
        top: rem(-21);
        left: rem(8);
        color: $gray2;
        font-weight: 500;
        font-size: rem(21);
        line-height: rem(22);
        position: absolute;
        display: inline-block;
        padding: rem(10) rem(30);
        text-transform: uppercase;
        background: linear-gradient(90deg, #F5B835 0%, #FBD864 100%)
    }

    &__title {
        font-weight: 500;
        font-size: rem(14);
        line-height: rem(15);
        margin-bottom: rem(18);
        text-transform: uppercase;
    }

    &__info {
        font-size: rem(14);
        line-height: rem(21);
        margin-bottom: rem(24);

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        li {
            min-height: rem(22);
            padding-left: rem(34);
            background: url("../img/icon__check.svg") 0 0 no-repeat;

            &:not(:last-child) {
                margin-bottom: rem(10);
            }
        }
    }

    &__footer {
        display: flex;
        align-items: center;
    }

    @include media-breakpoint-up(md) {

        .swiper-slide {
            width: rem(416);
        }

        &__content {
            padding: rem(56) rem(24) rem(42) rem(23);
        }
    }

    @include media-breakpoint-up(xl) {

        .swiper-wrapper {
            display: grid;
            grid-column-gap: rem(24);
            grid-template-columns: 1fr 1fr 1fr;
        }

        .swiper-slide {
            width: auto;
        }
    }

    @include media-breakpoint-up(xxl) {}
}

.showcase-term {
    white-space: nowrap;
    display: flex;
    align-items: center;
    margin-right: rem(20);

    &__icon {
        fill: $black;
        font-size: 0;
        line-height: 0;
        flex-shrink: 0;
        height: rem(32);
        display: inline-block;
    }

    &__content {
        font-size: rem(14);
        line-height: rem(14);
        padding-left: rem(8);
        text-transform: uppercase;
    }

    &__label {
        color: $gray4;
        font-size: rem(12);
        line-height: rem(12);
    }

    &__value {
        font-weight: 500;
    }

    @include media-breakpoint-up(md) {
        margin-right: rem(40);
    }
}

.showcase-price {
    white-space: nowrap;
    padding: rem(4) rem(14);
    border: 2px solid $yellow;

    &__label {
        color: $gray4;
        font-size: rem(12);
        line-height: rem(12);
        text-transform: uppercase;
    }

    &__value {
        font-weight: 500;
        font-size: rem(24);
        line-height: rem(24);
    }

    @include media-breakpoint-up(md) {}
}
