@import './inputs';
@import './checkboxes';
@import './radiobuttons';
@import './choices';
@import './range';
@import './quantity';

.form-group {
    margin-bottom: rem(18);
}
