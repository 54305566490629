.portfolio {
    position: relative;

    &__item {
        display: block;
    }

    &__image {
        display: block;
        height: rem(210);
        position: relative;

        &:before {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 5;
            content: '';
            display: block;
            position: absolute;
            background: rgba(0, 0, 0, 0.35);
        }

        &:after {
            top: calc(50% - 32px);
            left: calc(50% - 32px);
            z-index: 20;
            content: '';
            width: 64px;
            height: 64px;
            display: block;
            border-radius: 50%;
            position: absolute;
            background: url("../img/play.svg") 50% 50% no-repeat;
            background-size: cover;
        }
    }

    &__content {
        color: $gray2;
        font-size: rem(14);
        line-height: rem(18);
        padding-top: rem(22);
    }

    &__title {
        font-weight: 500;
        font-size: rem(18);
        line-height: rem(24);
        text-transform: uppercase;
    }

    &__text {
        margin-top: rem(12);
    }

    &__nav {
        top: rem(76);
        z-index: 10;
        position: absolute;

        &--prev {
            left: rem(-12);
        }

        &--next {
            right: rem(-12);
        }
    }

    @include media-breakpoint-up(md) {

        &__image {
            height: rem(416);

            &:after {
                top: calc(50% - 63px);
                left: calc(50% - 63px);
                width: 126px;
                height: 126px;
            }
        }

        &__nav {
            top: rem(170);
        }
    }

    @include media-breakpoint-up(lg) {
        overflow: hidden;

        &__main {
            margin: 0 auto;
            width: rem(620);
        }

        &__slider {
            overflow: visible;
        }

        &__nav {

            &--prev {
                left: rem(36);
            }

            &--next {
                right: rem(36);
            }
        }

        .swiper-slide {

            .portfolio__image {

                &:before {
                    background: rgba(0, 0, 0, 0.75);
                }
            }

            .portfolio__content {
                opacity: 0;
                visibility: hidden;
            }
        }
        .swiper-slide-active {

            .portfolio__image {

                &:before {
                    background: rgba(0, 0, 0, 0.35)
                }
            }

            .portfolio__content {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    @include media-breakpoint-up(xl) {

        &__main {
            width: rem(856);
        }

        &__image {
            height: rem(517);

            &:after {
                top: calc(50% - 80px);
                left: calc(50% - 80px);
                width: 160px;
                height: 160px;
            }
        }

        &__nav {
            top: rem(228);
        }
    }

    @include media-breakpoint-up(xxl) {

        &__nav {

            &--prev {
                left: rem(66);
            }

            &--next {
                right: rem(66);
            }
        }
    }
}
