@import 'node_modules/normalize.css/normalize';


*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: 16px;
    line-height: 1.5;
}
body {
    min-height: 100%;
    color: $primary;
    background-color: $white;
    font-family: $font-family-primary;
    font-size: rem(16);
}


// Typography
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 0;
    padding: 0;
    font-weight: 400;
    line-height: 1.2;
    font-family: $font-family-secondary;
}

h1, .h1 {
    font-size: rem(36);
    margin-bottom: rem(32);
}

h2, .h2 {
    font-size: rem(30);
    line-height: rem(36);
    margin: 0 0 rem(30) 0;
}

h3, .h3 {
    font-size: rem(24);
    margin-bottom: rem(20);
}

h4, .h4 {
    font-size: rem(20);
    margin-bottom: rem(20);
}

h5, .h5 {
    font-size: rem(16);
    margin-bottom: rem(20);
}

h6, .h6 {
    font-size: rem(14);
    margin-bottom: rem(20);
}

a {
    color: $primary;
    text-decoration: underline;

    &:hover {
        color: $primary;
        text-decoration: none;
    }
}

p {
    margin-top: 0;
    margin-bottom: $paragraph-margin-bottom;
}

// Lists

ol,
ul {
    padding-left: rem(32);
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: rem(20);
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}


