// Options
$enable-grid-classes:         true !default;


// colors

$white:         #fff !default;
$black:         #000 !default;
$gray:          #333 !default;
$gray-light:    #BDBDBD !default;
$gray1:          #333 !default;
$gray2:         #4F4F4F !default;
$gray4:         #BDBDBD !default;
$gray5:         #E0E0E0 !default;
$yellow:        #F5B835 !default;
$yellow-light:  #FBD864 !default;

$primary:       #000 !default;
$secondary:     #000 !default;

$gradient:      linear-gradient(90deg, #F5B835 0%, #FBD864 100%) !default;

$html-font-size: 16px;
$font-size-base:            1rem !default;
$line-height-base:          1.5 !default;

$font-family-primary: 'Ubuntu', Arial, sans-serif;
$font-family-secondary: 'Fira Sans', Arial, sans-serif;

$border-radius: rem(4);
$transition-time: 0.2s;
$box-shadow: 0 0 0 #fff;

$paragraph-margin-bottom:   rem(20) !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (
    0: 0,
    1: $spacer / 4,
    2: $spacer / 2,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
) !default;

// Grid

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1230px,
    xxl: 1350px
) !default;

$container-max-widths: (
    sm: 34rem,
    md: 47rem,
    lg: 60rem,
    xl: 75rem,
    xxl: 83rem
) !default;


$grid-columns:                12 !default;
$grid-gutter-width:           2rem !default;
$grid-row-columns:            6 !default;

$gutters: $spacers !default;

$container-padding-x: $grid-gutter-width !default;


