.footer {
    font-size: rem(14);
    line-height: rem(20);
    padding-top: rem(32);
    padding-bottom: rem(22);
    border-top: 1px solid #D5DEE4;

    &__logo {
        display: flex;
        justify-content: center;
        margin-bottom: rem(30);
    }

    &__text {
        text-align: center;
        color: $gray-light;
        font-weight: 300;
        font-size: rem(14);
        line-height: rem(15);
        margin-bottom: rem(10);
    }

    &__content {
        margin-bottom: rem(22);
    }

    &__nav {
        padding: 0 rem(20);
        margin-bottom: rem(46);
    }

    &__contact {
        padding-right: rem(26);
    }

    &__callback {
        display: flex;
        justify-content: flex-end;
    }

    &__calc {
        display: none;
    }

    &__city {
        padding: 0 rem(20);
    }

    @include media-breakpoint-up(md) {

        &__body {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__content {
            width: rem(210);
            flex-shrink: 0;
        }

        &__logo {
            justify-content: flex-start;
        }

        &__text {
            text-align: left;
            margin-bottom: rem(20);
        }

        &__city {
            padding: 0;
        }

        &__nav {
            flex-grow: 1;
            padding: 0 0 0 rem(20);

            ul {
                width: 50%;
            }
        }

        &__contact {
            width: 100%;
            padding-right: 0;
        }

        &__calc {
            bottom: rem(30);
            left: rem(226);
            display: block;
            position: absolute;
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: rem(40);
        padding-bottom: rem(30);

        &__content {
            margin-bottom: 0;
            margin-right: rem(40);
        }

        &__body {
            flex-wrap: nowrap;
        }

        &__contact {
            width: auto;
        }

        &__nav {
            margin-bottom: 0;
        }

        &__calc {
            right: 0;
            left: auto;
            bottom: 0;
        }
    }

    @include media-breakpoint-up(xl) {

        &__content {
            margin-right: 0;
        }

        &__nav {
            padding: 0;
            width: rem(312);
            flex-grow: 0;
            flex-shrink: 0;

            ul {
                width: auto;
            }
        }

        &__calc {
            right: auto;
            bottom: auto;
            position: relative;
        }
    }
}

.footer-offer {
    font-size: rem(12);
    line-height: 136%;
    color: $gray-light;
    text-align: center;
    background: #F7FBFE;
    padding: rem(16) 0 rem(24) 0;

    @include media-breakpoint-up(md) {
        padding: rem(20) 0;
        font-size: rem(14);
    }
}

.footer-logo {
    font-weight: 300;
    font-size: rem(12);
    line-height: 105%;
    color: $gray-light;
    text-decoration: none;
    display: flex;
    align-items: center;

    &:hover {
        color: $gray-light;
        text-decoration: none;
    }

    &__image {
        width: rem(55);
        display: block;
        flex-shrink: 0;
    }

    &__text {
        display: block;
        margin-left: rem(10);
        padding-left: rem(10);
        border-left: 1px solid $gray-light;
    }
}

.footer-city {
    color: $gray-light;
    font-size: rem(14);
    line-height: rem(15);
    align-items: center;
    display: inline-flex;
    white-space: nowrap;

    &__label {
        margin-right: 6px;
        display: inline-block;
    }

    &__name {
        margin: 0;
        padding: 0;
        border: none;
        outline: none;
        color: $yellow;
        cursor: pointer;
        display: inline-block;
        text-decoration: underline;
        background: transparent;

        &:hover {
            color: $yellow-light;
            text-decoration: none;
        }
    }
}

.footer-nav {
    font-weight: 500;
    font-size: rem(14);
    line-height: rem(15);
    white-space: nowrap;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {

            &:not(:last-child) {
                margin-bottom: rem(26);
            }

            a {
                color: $black;
                text-decoration: none;

                &:hover {
                    color: $yellow;
                    text-decoration: none;
                }
            }
        }
    }
}

.footer-contact {
    height: rem(37);
    margin-bottom: rem(24);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    &__phone {
        color: #333;
        font-weight: 400;
        font-size: rem(24);
        line-height: rem(24);
        white-space: nowrap;
        display: inline-block;
        text-decoration: none;

        &:hover {
            color: #333;
            text-decoration: none;
        }
    }

    &__worktime {
        right: 0;
        top: calc(100% - 6px);
        color: $gray-light;
        font-weight: 400;
        font-size: rem(14);
        line-height: rem(14);
        white-space: nowrap;
        position: absolute;
        display: inline-block;

        &:before {
            left: rem(-8);
            top: calc(50% - 1px);
            width: 4px;
            height: 4px;
            content: '';
            position: absolute;
            border-radius: 50%;
            background: linear-gradient(180deg, #AECF53 0%, #89BB45 100%);
        }
    }

    &__messenger {
        width: 37px;
        height: 37px;
        display: block;
        overflow: hidden;
        border-radius: 50%;
        margin-right: rem(5);
    }
}



@include media-breakpoint-up(md) {}
@include media-breakpoint-up(lg) {}
@include media-breakpoint-up(xl) {}
@include media-breakpoint-up(xxl) {}
