.offer {
    padding-top: rem(46);
    padding-bottom: rem(52);
    background: url("../img/offer__bg.webp") 50% 50% no-repeat;

    &__main {
        color: $gray1;
        background: $white;
        position: relative;
        padding-top: rem(18);
        padding-bottom: rem(38);

        &:after,
        &:before {
            top: 0;
            bottom: 0;
            z-index: 1;
            content: '';
            width: rem(1000);
            background: $white;
            position: absolute;
        }

        &:after {
            left: 100%;
        }

        &:before {
            right: 100%;
        }
    }

    &__header {
        font-weight: 700;
        font-size: rem(30);
        line-height: rem(36);
        margin-bottom: rem(14);
        font-family: $font-family-secondary;
    }

    &__text {
        font-size: rem(20);
        line-height: rem(25);
        margin-bottom: rem(16);
        font-family: $font-family-secondary;
    }

    &__content {
        z-index: 30;
        position: relative;
    }

    &__image {
        display: none;
    }

    @include media-breakpoint-up(md) {
        padding-top: rem(65);
        padding-bottom: rem(80);

        &__main {
            height: 358px;

            &:after {
                left: rem(360);
                right: auto;
                width: 605px;
                height: 358px;
                background: url("../img/offer__pattern.svg") 0 0 no-repeat;
            }
        }

        &__form {
            width: rem(316);
        }

        &__header {
            font-size: rem(40);
            line-height: rem(51);
            margin-bottom: rem(4);
        }

        &__text {
            margin-bottom: rem(20);
        }

        &__image {
            bottom: 0;
            z-index: 10;
            left: rem(516);
            width: 468px;
            display: block;
            position: absolute;
        }
    }

    @include media-breakpoint-up(lg) {

        &__main {
            padding-left: rem(30);

            &:before {
                display: none;
            }

            &:after {
                right: 0;
                width: auto;
                left: rem(380);
            }
        }

        &__image {
            left: rem(530);
        }
    }

    @include media-breakpoint-up(xl) {

        &__main {
            left: rem(-40);
            margin: 0 auto;
            width: rem(1002);
            padding-left: rem(42);

            &:after {
                width: 605px;
                left: auto;
                right: 0;
            }
        }

        &__image {
            left: auto;
            right: rem(-20);
        }
    }

    @include media-breakpoint-up(xxl) {}
}
