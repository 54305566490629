.deadlines {
    padding: rem(32) 0;
    background: #F7FBFE;

    &__header {
        font-size: rem(30);
        line-height: rem(36);
        margin-bottom: rem(22);
        font-family: $font-family-secondary;
    }

    &__main {
        margin: 0 rem(-16);
    }

    &__footnote {
        color: $gray2;
        font-size:rem(14);
        line-height: rem(19);
        margin-top: rem(8);
    }

    &__table {
        width: 100%;
        border: none;
        margin-bottom: rem(8);
        border-collapse: collapse;
        max-width: 100%;
        background-color: transparent;

        thead {

            tr {
                background: linear-gradient(90deg, #F5B835 0%, #FBD864 100%);
            }
        }

        tbody {

            tr {

                &:nth-child(even) {
                    background: $white;
                }
            }
        }

        th {
            color: $gray2;
            text-align: left;
            font-weight: 700;
            font-size: rem(14);
            line-height: rem(15);
            white-space: nowrap;
            padding: rem(26) rem(22);
            text-transform: uppercase;

            &:first-child {
                padding-left: rem(16);
            }

            &:last-child {
                padding-right: rem(16);
            }
        }

        td {
            font-weight: 300;
            font-size: rem(21);
            white-space: nowrap;
            line-height: rem(22);
            padding: rem(22) rem(22);
            text-transform: uppercase;

            &:first-child {
                padding-left: rem(16);
            }

            &:last-child {
                padding-right: rem(16);
            }
        }
    }

    @include media-breakpoint-up(md) {
        padding: rem(44) 0 rem(50) 0;
    }
}
