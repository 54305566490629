.question {
    color: $white;
    padding-top: rem(40);
    padding-bottom: rem(40);
    background: url("../img/question__bg.webp") 50% 50% no-repeat;
    background-size: cover;

    &__main {
        position: relative;
        padding-top: rem(24);
        padding-bottom: rem(12);
        background: rgba(255, 255, 255, 0.15);

        &:after,
        &:before {
            top: 0;
            bottom: 0;
            z-index: 1;
            content: '';
            width: rem(1000);
            position: absolute;
            background: rgba(255, 255, 255, 0.15);
        }

        &:after {
            left: 100%;
        }

        &:before {
            right: 100%;
        }
    }

    &__header {
        font-weight: 700;
        font-size: rem(30);
        line-height: rem(36);
        margin-bottom: rem(22);
        font-family: $font-family-secondary;
    }

    &__row {
        display: grid;
        grid-row-gap: rem(18);
        grid-column-gap: rem(24);
        grid-template-columns: 1fr;
    }

    &__privacy {
        font-size: rem(14);
        line-height: rem(18);
        margin-top: rem(18);

        a {
            color: $white;
            text-decoration: underline;

            &:hover {
                color: $white;
                text-decoration: none;
            }
        }
    }

    @include media-breakpoint-up(md) {

        &__main {
            padding-bottom: rem(38);
        }

        &__row {
            grid-row-gap: rem(14);
            grid-template-columns: 1fr 1fr;
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: rem(30);
        padding-bottom: rem(30);

        &__main {
            padding-top: rem(28);
            padding-bottom: rem(28);
        }

        &__header {
            font-size: rem(40);
            line-height: rem(52);
            margin-bottom: rem(22);
        }

        &__row {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    @include media-breakpoint-up(xl) {

        &__main {
            max-width: rem(1186);
            padding-left: rem(100);
            padding-right: rem(100);

            &:after,
            &:before {
                display: none;
            }
        }
    }

    @include media-breakpoint-up(xxl) {

        &__main {
            padding-left: rem(110);
            padding-right: rem(110);
        }
    }
}


