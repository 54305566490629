.estimation {
    color: $white;
    padding-top: rem(42);
    padding-bottom: rem(54);
    background: url("../img/estimation__bg.webp") 50% 50% no-repeat;
    background-size: cover;

    &__header {
        color: $yellow;
        font-weight: 700;
        font-size: rem(30);
        line-height: rem(38);
        margin-bottom: rem(28);
        font-family: $font-family-secondary;
    }

    &__main {
        margin-bottom: rem(20);
        display: flex;
        align-items: flex-start;
    }

    &__image {
        width: rem(110);
        height: rem(110);
        display: block;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;
    }

    &__content {
        font-size: rem(14);
        line-height: rem(18);
        padding-left: rem(12);
    }

    &__name {
        font-weight: 700;
        font-size: rem(21);
        line-height: rem(27);
    }

    &__staff {
        font-size: rem(18);
        line-height: rem(24);
        margin-bottom: rem(15);
    }

    &__projects {
        white-space: nowrap;
        font-size: rem(16);
        line-height: rem(20);
        margin-bottom: rem(15);

        a {
            color: $yellow;
            text-decoration: underline;

            &:hover {
                color: $yellow;
                text-decoration: none;
            }
        }
    }

    @include media-breakpoint-up(md) {
        min-height: rem(503);
        padding-bottom: rem(90);

        &__header {
            max-width: rem(770);
            font-size: rem(40);
            line-height: rem(50);
            margin-bottom: rem(40);
        }

        &__wrap {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }

        &__form {
            width: rem(310);
            flex-shrink: 0;
        }

        &__image {
            width: rem(140);
            height: rem(140);
        }

        &__main {
            margin-bottom: 0;
            padding-right: rem(20);
        }
    }

    @include media-breakpoint-up(lg) {

        &__wrap {
            width: rem(910);
        }

        &__header {
            margin-bottom: rem(60);
        }

        &__image {
            width: rem(175);
            height: rem(175);
        }

        &__content {
            padding-left: rem(16);
        }

        &__text {
            width: rem(320);
        }

        &__form {
            width: rem(316);
        }
    }

    @include media-breakpoint-up(xl) {

        &__header {
            margin-left: rem(110);
        }

        &__wrap {
            margin-left: rem(110);
        }
    }
}
