.services {

    &__primary {
        margin-bottom: rem(15);
        display: grid;
        grid-row-gap: rem(15);
        grid-template-columns: 1fr;
    }

    &__secondary {
        display: grid;
        grid-row-gap: rem(15);
        grid-column-gap: rem(12);
        grid-template-columns: 1fr 1fr;
    }

    @include media-breakpoint-up(md) {

        &__primary {
            margin-bottom: rem(24);
            grid-row-gap: rem(24);
        }

        &__secondary {
            grid-row-gap: rem(24);
            grid-column-gap: rem(24);
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        }
    }

    @include media-breakpoint-up(xl) {
        display: grid;
        grid-column-gap: rem(24);
        grid-template-columns: 1fr 1fr;

        &__primary {
            margin-bottom: 0;
        }
    }
}

.service {
    overflow: hidden;
    position: relative;

    &__media {
        top: 0;
        right: 0;
        bottom: 0;
        font-size: 0;
        line-height: 0;
        position: absolute;
    }

    &__image {
        height: 100%;
        position: relative;
    }

    &__content {
        z-index: 20;
        height: 100%;
        position: relative;
        font-size: rem(14);
        line-height: rem(15);
        background: rgba(247, 251, 254, 0.75);
        padding: rem(38) rem(22) rem(26) rem(22);
        display: flex;
        flex-direction: column;
    }

    &__title {
        font-weight: 500;
        font-size: rem(16);
        line-height: rem(17);
        margin-bottom: rem(12);
    }

    &__button {
        margin-top: rem(34);

        .btn {
            min-width: rem(152);
        }
    }

    &__price {

        table {
            width: 100%;
            border: none;
            border-collapse: collapse;
        }

        td {
            border: none;
            padding: rem(6) 0;
        }

        tr {

            &:first-child {

                td {
                //    padding-top: 0;
                }
            }

            &:last-child {

                td {
                    padding-bottom: 0;
                }
            }
        }

        a {
            color: $black;
            text-decoration: none;
            border-bottom: 1px solid $yellow;

            &:hover {
                color: $black;
                text-decoration: none;
                border-bottom-color: transparent;
            }
        }
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: grid;
        grid-row-gap: rem(12);
        grid-column-gap: rem(12);
        grid-template-columns: 1fr 1fr;

        a {
            color: $black;
            text-decoration: none;
            border-bottom: 1px solid $yellow;

            &:hover {
                color: $black;
                text-decoration: none;
                border-bottom-color: transparent;
            }
        }
    }

    &__wrap {
        flex-grow: 1;
    }

    &--01 {
        height: rem(344);

        .service {

            &__content {
                width: calc(100% - 26px);
            }

            &__image {
               right: rem(-100);
            }
        }
    }

    &--02 {
        height: rem(270);

        .service {

            &__content {
                width: rem(248);
            }

            &__image {
                right: rem(-120);
            }
        }
    }

    &--03 {
        height: rem(216);
        grid-column: span 2;

        .service {

            &__content {
                width: rem(248);
            }

            &__media {

                &:before {
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 5;
                    content: '';
                    position: absolute;
                    background: linear-gradient(90deg, #FFFFFF 7.03%, rgba(255, 255, 255, 0) 73.2%);
                }
            }

            &__image {
            //    right: rem(-120);
            }

            &__list {
                grid-template-columns: 1fr;
            }
        }
    }

    &--04 {
        height: rem(248);
        grid-column: span 2;

        .service {

            &__content {
                width: rem(248);
            }

            &__media {

                &:before {
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 5;
                    content: '';
                    position: absolute;
                    background: linear-gradient(90deg, #FFFFFF 7.03%, rgba(255, 255, 255, 0) 73.2%);
                }
            }
        }
    }

    &--05 {
        grid-column: span 2;

        .service {

            &__content {
                width: rem(248);
            }

            &__list {
                grid-template-columns: 1fr;
            }

            &__media {

                &:before {
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 5;
                    content: '';
                    position: absolute;
                    background: linear-gradient(90deg, #FFFFFF 7.03%, rgba(255, 255, 255, 0) 73.2%);
                }
            }
        }
    }

    &--06 {
        height: rem(296);
        grid-column: span 1;

        .service {

            &__button {

                .btn {
                    min-width: rem(130);
                }
            }
        }
    }

    &--07 {
        height: rem(296);
        grid-column: span 1;

        .service {
            &__button {

                .btn {
                    min-width: rem(130);
                }
            }
        }
    }

    @include media-breakpoint-up(md) {

        &__content {
            padding: rem(38) rem(22) rem(26) rem(22);
        }

        &--01 {

            .service {

                &__content {
                    width: rem(320);
                }

                &__media {

                    &:before {
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 5;
                        content: '';
                        position: absolute;
                        background: linear-gradient(90deg, #FFFFFF 7.03%, rgba(255, 255, 255, 0) 73.2%);
                    }
                }
            }
        }

        &--02 {
            height: rem(214);

            .service {

                &__content {
                    width: rem(410);
                }

                &__list {
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                }

                &__media {

                    &:before {
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 5;
                        content: '';
                        position: absolute;
                        background: linear-gradient(90deg, #FFFFFF 7.03%, rgba(255, 255, 255, 0) 73.2%);
                    }
                }

                &__image {
                    right: 0;
                }
            }
        }

        &--03 {
            height: rem(260);
            grid-column: span 3;

            .service {

                &__content {
                    width: rem(240);
                }
            }
        }

        &--04 {
            height: rem(260);
            grid-column: span 3;

            .service {

                &__content {
                    width: rem(240);
                }
            }
        }

        &--05 {
            height: rem(298);
            grid-column: span 2;

            .service {

                &__title {
                    width: rem(140);
                }
            }
        }

        &--06 {
            height: rem(298);
            grid-column: span 2;

            .service {

                &__title {
                    width: rem(140);
                }
            }
        }

        &--07 {
            height: rem(298);
            grid-column: span 2;

            .service {

                &__title {
                    width: rem(120);
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {

        &--01 {

            .service {

                &__content {
                    width: rem(380);
                }
            }
        }
    }


    @include media-breakpoint-up(xl) {

        &--01 {

            .service {

                &__content {
                    width: rem(320);
                }
            }
        }

        &--02 {

            .service {

                &__content {
                    width: rem(398);
                }

                &__media {

                    &:before {

                    }
                }
            }
        }

        &--03 {

            .service {

                &__content {
                    width: rem(214);
                }
            }
        }

        &--04 {

            .service {

                &__content {
                    width: rem(244);
                }
            }
        }
    }

    @include media-breakpoint-up(xxl) {

        &__content {
            padding-left: rem(24);
        }

        &--02 {

            .service {

                &__content {
                    width: rem(410);
                }

                &__media {

                    &:before {

                    }
                }
            }
        }
    }

}
