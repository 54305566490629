.works {

    &__slider  {

    }

    &__navigation {
        padding-top: rem(36);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__pagination {
        padding: 0;
        margin: 0 rem(10);
        font-size: 0;
        line-height: 0;
        flex-grow: 1;
        position: relative;
        display: flex;
        align-items: center;
    }

    .swiper-pagination-bullet {
        background: #D9D9D9;
        height: rem(8);
        margin: 0 rem(5);
        flex-grow: 1;
        cursor: pointer;
        display: block;
    }

    .swiper-pagination-bullet-active {
        background: linear-gradient(90deg, #F5B835 0%, #FBD864 100%);
    }

    &__nav {
        margin: 0;
        padding: 0;
        border: none;
        outline: none;
        font-size: 0;
        line-height: 0;
        width: rem(28);
        height: rem(8);
        display: block;
        cursor: pointer;
        overflow: hidden;
        position: relative;
        background: transparent;
        flex-shrink: 0;
        fill: $yellow;

        i {
            top: 0;
            width: 90px;
            height: 100%;
            position: absolute;
        }

        &--prev {

            i {
                left: 0;
            }
        }

        &--next {

            i {
                right: 0;
            }
        }
    }

    @include media-breakpoint-up(md) {

        &__navigation {
            width: rem(640);
            margin: 0 auto;
            padding-top: rem(42);
        }

        &__nav {
            width: 90px;
        }

        &__pagination {
            margin: 0 rem(24);
        }

        .swiper-pagination-bullet {
            margin: 0 rem(7);
        }
    }
}

.work {

    &__content {
        color: #4F4F4F;
       padding-top: rem(32);
    }

    &__header {
        color: $black;
        font-weight: 500;
        font-size: rem(24);
        line-height: rem(30);
        margin-bottom: rem(12);
    }

    &__title {
        font-weight: 500;
        font-size: rem(14);
        line-height: rem(15);
        margin-bottom: rem(12);
        text-transform: uppercase;
    }

    &__info {
        font-size: rem(14);
        line-height: rem(21);
        margin-bottom: rem(16);

        ul {
            padding-left: rem(24);
        }
    }

    &__data {
        padding: 0;
        font-size: rem(12);
        line-height: rem(13);
        list-style: none;
        margin: 0 0 rem(16) 0;
        display: flex;
        justify-content: space-between;

        li {
            height: rem(42);
            padding: rem(8) 0 0 rem(40);

            &:nth-child(1) {
                background: url("../img/square.svg") 0 50% no-repeat;
            }

            &:nth-child(2) {
                background: url("../img/term.svg") 0 50% no-repeat;
            }

            &:nth-child(3) {
                padding-left: rem(50);
                background: url("../img/rooms.svg") 0 50% no-repeat;
            }
        }
    }

    &__label {
        color: #BDBDBD;
    }

    &__footer {
        display: flex;
    }

    &__price {
        height: rem(52);
        width: rem(150);
        font-size: rem(12);
        line-height: rem(13);
        padding-left: rem(14);
        white-space: nowrap;
        border: 2px solid $yellow;
        display: flex;
        align-items: center;

        span {
            color: #BDBDBD;
            display: block;
        }

        strong {
            display: block;
            font-weight: 500;
            font-size: rem(22);
            line-height: rem(24);
        }
    }

    &__button {
        flex-grow: 1;
        padding-left: rem(6);

        .btn {
            width: 100%;
            padding-left: rem(8);
            padding-right: rem(8);
        }
    }

    &__image {
        height: rem(386);
    }

    &__nav {
        z-index: 20;
        top: calc(50% - 30px);
        position: absolute;

        &--prev {
            left: rem(8);
        }

        &--next {
            right: rem(8);
        }
    }

    @include media-breakpoint-up(md) {

        &__button {
            flex-grow: 0;

            .btn {
                width: auto;
                padding-left: rem(40);
                padding-right: rem(40);
            }
        }

        &__price {
            margin-right: rem(22);
        }

        &__row {
            margin-bottom: rem(10);
            display: flex;
            justify-content: space-between;
        }

        &__info {
            margin-bottom: 0;
        }

        &__data {
            text-transform: uppercase;
            display: block;
            margin-bottom: 0;

            li {

                &:not(:last-child) {
                    margin-bottom: rem(14);
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        display: flex;

        &__media {
            width: 700px;
            flex-shrink: 0;
        }

        &__header {
            margin-bottom: rem(24);
        }

        &__content {
            flex-grow: 1;
            max-width: rem(480);
            padding: 0 0 0 rem(24);
        }

        &__row {
            display: block;
            margin-bottom: rem(24);
        }

        &__data {
            padding-left: rem(16);
            display: flex;
            justify-content: flex-start;

            li {

                &:not(:last-child) {
                    margin-bottom: 0;
                    margin-right: rem(42);
                }
            }
        }
    }

    @include media-breakpoint-up(xxl) {

        &__media {
            width: 746px;
        }
    }
}

@include media-breakpoint-up(md) {}
@include media-breakpoint-up(lg) {}
@include media-breakpoint-up(xl) {}
@include media-breakpoint-up(xxl) {}
