.root {
    padding-top: rem(72);
    width: 100%;
    min-height: 100vh;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
        padding-top: 0;
    }
}

.main {
    flex-grow: 1;
    padding: rem(40) 0;
}

.swiper {
    width: 100%;
    height: 100%;
}

.modal {
    padding: rem(24);

    @include media-breakpoint-up(md) {
        padding: rem(32);
    }
}

.primary {
    background: #F7FBFE;
    position: relative;
    margin-bottom: rem(50);

    .container {
        z-index: 10;
        position: relative;
    }

    &__wrap {
        position: relative;
        padding-top: rem(62);
        padding-bottom: rem(74);
    }

    &__main {
        z-index: 20;
        position: relative;
    }

    &__header {
        width: rem(300);
        font-weight: 700;
        font-size: rem(35);
        line-height: 100%;
        margin-bottom: rem(16);
        font-family: $font-family-secondary;
    }

    &__price {
        padding: rem(5);
        font-size: rem(24);
        line-height: rem(25);
        background: $gradient;
        display: inline-block;
        margin-bottom: rem(24);

        span {
            padding: rem(4) rem(24);
            display: block;
            background: $white;
        }

        strong {
            margin: 0 rem(4);
            font-size: rem(40);
            line-height: rem(42);
        }
    }

    &__title {
        font-weight: 500;
        font-size: rem(16);
        line-height: rem(18);
        margin-bottom: rem(18);
    }

    &__list {
        width: rem(280);
        font-size: rem(14);
        line-height: rem(15);
        padding: 0 0 0 rem(10);
        list-style: none;
        margin: 0 0 rem(34) 0;
        display: grid;
        grid-row-gap: rem(12);
        grid-column-gap: rem(12);
        grid-template-columns: 1fr 1fr;

        li {
            padding: rem(2) 0 rem(2) rem(23);
            background: url("../img/check_circle.svg") 0 50% no-repeat;

            a,
            span {
                color: #4F4F4F;
                text-decoration: none;
                display: inline-block;
                border-bottom: 1px solid $yellow;

                &:hover {
                    color: #4F4F4F;
                    text-decoration: none;
                }
            }
        }
    }

    &__button {

        &:first-child {
            margin-bottom: rem(20);
        }
    }

    &__media {
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        left: 30%;
        overflow: hidden;
        position: absolute;

        &:before {
            top: 0;
            left: 0;
            bottom: 0;
            z-index: 5;
            content: '';
            width: rem(200);
            position: absolute;
            background: linear-gradient(90deg, #F7FBFE 25.67%, rgba(255, 255, 255, 0) 100%);
        }
    }

    &__image {
        left: -220px;
        height: 100%;
        position: relative;
    }

    [data-tiles] {
        overflow: visible;
    }

    .swiper-slide {
        width: auto;
    }

    &__tiles {
        left: 0;
        right: 0;
        bottom: rem(-50);
        position: absolute;
    }

    &__tile {
        height: rem(96);
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: rem(14);
        line-height: rem(19);
        text-transform: uppercase;
        padding: rem(16) rem(16) rem(16) rem(70);
        border-left: 5px solid $yellow;
        box-shadow: 0px 4px 15px #DBE6EE;
        background-color: $white;
        background-repeat: no-repeat;
        background-position: 17px 50%;

        &--01 {
            background-image: url("../img/primary_tile__award.svg");
        }

        &--02 {
            background-image: url("../img/primary_tile__diamond.svg");
        }

        &--03 {
            background-image: url("../img/primary_tile__doc.svg");
        }
    }

    @include media-breakpoint-up(md) {
        margin-bottom: rem(62);

        &__wrap {
            min-height: rem(560);
            padding-top: rem(72);
            padding-bottom: rem(80);
        }

        &__header {
            width: rem(480);
            font-size: rem(60);
        }

        &__list {
            width: rem(430);
            margin-bottom: rem(42);
            grid-template-columns: 1fr 1fr 1fr;
        }

        &__buttons {
            display: flex;
            align-items: center;
        }

        &__button {

            &:first-child {
                margin-bottom: 0;
                margin-right: rem(12);
            }
        }

        &__tiles {
            bottom: rem(-62);
        }

        .swiper-slide {
            flex-grow: 1;

            &:not(:last-child) {
                margin-right: rem(12);
            }
        }

        &__media {
            left: 40%;
        }

        &__image {
            left: -150px;
        }
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;

        &__wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__tiles {
            left: auto;
            right: auto;
            bottom: auto;
            position: relative;
            display: block;
            width: rem(250);
        }

        .swiper-wrapper {
            display: block;
        }

        .swiper-slide {
            width: 100%;

            &:not(:last-child) {
                margin-right: 0;
                margin-bottom: rem(16);
            }
        }
    }

    @include media-breakpoint-up(xl) {

        &__media {
            left: calc(50% - 185px);
        }

        &__image {
            left: 0;
        }

        &__tiles {
            margin-right: rem(34);
        }
    }
}

.section {
    padding-top: rem(70);

    &--last {
        padding-bottom: rem(70);
    }

    &__header {
        font-size: rem(30);
        line-height: rem(30);
        margin-bottom: rem(24);
        font-family: $font-family-secondary;
    }

    &--sm {
        display: none;
    }

    @include media-breakpoint-up(xl) {
        padding-top: rem(80);

        &__header {
            margin-bottom: rem(36);
        }

        &--last {
            padding-bottom: rem(80);
        }

        &--sm {
            display: block;
        }
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
}

.pricing {
    display: grid;
    grid-column-gap: rem(24);
    grid-template-columns: 195px auto;

    &__nav {
        font-size: rem(14);
        line-height: rem(15);
        padding: 0;
        list-style: none;
        margin: 0 0 rem(35) 0;

        li {

            &:not(:last-child) {
                margin-bottom: rem(12);
            }
        }
    }

    &__button {
        margin: 0;
        padding: 0;
        color: #4F4F4F;
        border: none;
        outline: none;
        cursor: pointer;
        text-decoration: none;
        display: inline-block;
        background: transparent;
        border-bottom: 1px solid $yellow;
        transition: .3s;

        &:hover {
            border-bottom-color: transparent;
            transition: .3s;
        }

        &.active {
            color: $yellow;
            text-decoration: none;
            border-bottom-color: transparent;
            transition: .3s;
        }
    }

    &__man {
        width: 129px;
        height: 129px;
        overflow: hidden;
        border-radius: 50%;
    }

    &__action {
        z-index: 10;
        margin-top: rem(-20);
        position: relative;
        margin-bottom: rem(12);

        .btn {
            width: 100%;
            padding-left: rem(10);
            padding-right: rem(10);
        }
    }

    &__lead {
        color: #4F4F4F;
        font-weight: 400;
        font-size: rem(14);
        line-height: 136%;
    }

    &__header {
        margin-bottom: rem(12);
        display: flex;
        align-items: center;
    }

    &__name {
        color: $gray2;
        height: rem(48);
        font-weight: 500;
        font-size: rem(21);
        line-height: 104.5%;
        margin-right: rem(18);
        padding: rem(8) rem(32);
        text-transform: uppercase;
        background: linear-gradient(90deg, #F5B835 0%, #FBD864 100%);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__data {
        height: rem(48);
        padding: 0 rem(18);
        border: 2px solid $yellow;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__label {
        color: $gray4;
        font-size: rem(12);
        line-height: rem(12);
        text-transform: uppercase;
    }

    &__wrap {

    }

    &__value {
        color: $gray2;
        font-weight: 500;
        font-size: rem(24);
        line-height: rem(24);
    }

    &__text {
        font-size: rem(14);
        line-height: rem(18);
        margin-bottom: rem(24);

        p {
            margin: 0;
        }
    }

    &__row {
        display: grid;
        grid-column-gap: 24px;
        grid-template-columns: auto 380px;
    }

    &__term {
        display: flex;
        align-items: center;

        &__icon {
            font-size: 0;
            line-height: 0;
            height: rem(32);
            display: inline-block;
            flex-shrink: 0;
        }

        &__text {
            padding-left: rem(8);
            text-transform: uppercase;

            span {
                color: $gray4;
                display: block;
                font-weight: 400;
                font-size: rem(12);
                line-height: rem(12);
            }

            strong {
                color: $gray2;
                display: block;
                font-weight: 500;
                font-size: rem(14);
                line-height: rem(14);
            }
        }
    }

    &__content {
        padding: rem(24);
        background: #FFFFFF;
        box-shadow: 0px 0px 25px #DDE9ED;
    }

    &__title {
        color: $gray2;
        font-weight: 500;
        font-size: rem(14);
        line-height: 104.5%;
        margin-bottom: rem(16);
        text-transform: uppercase;
    }

    &__info {
        color: $gray2;
        font-size: rem(14);
        line-height: rem(22);
        margin: 0 0 rem(32) 0;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        li {
            position: relative;
            min-height: rem(22);
            padding: 0 0 0 rem(34);

            &:not(:last-child) {
                margin-bottom: rem(12);
            }

            &:before {
                top: 0;
                left: 0;
                content: '';
                width: 22px;
                height: 22px;
                position: absolute;
                background: url("../img/icon_check.svg") 0 0 no-repeat;
            }
        }
    }

    @include media-breakpoint-up(xxl) {

        &__row {
            grid-template-columns: auto 416px;
        }
    }
}

.pricing-media {
    display: grid;
    grid-row-gap: 8px;
    grid-column-gap: 24px;
    grid-template-rows: 110px 110px 110px;
    grid-template-columns: auto 160px;
    grid-template-areas:
    "image thumb1"
    "image thumb2"
    "image thumb3";

    &__image {
        display: block;
        grid-row: span 3;
    }

    @include media-breakpoint-up(xxl) {
        grid-template-columns: auto 196px;
    }
}


@include media-breakpoint-up(sm) {}
@include media-breakpoint-up(md) {}
@include media-breakpoint-up(lg) {}
@include media-breakpoint-up(xl) {}
@include media-breakpoint-up(xxl) {}
